import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import {
  ApplyLimitRequestParams,
  ApplyLimits,
  CreateLimit,
  Limit,
  LimitList,
  LimitRequestParams,
  UpdateLimit,
} from '@type/limits';
import { Observable } from 'rxjs';

@Injectable()
export class StrategyLimitApiService {
  private http = inject(HttpClient);

  create(params: LimitRequestParams, body: CreateLimit): Observable<Limit> {
    return this.http.post<Limit>(this.getUrl(params), body);
  }

  getList(params: LimitRequestParams): Observable<LimitList> {
    return this.http.post<LimitList>(this.getUrl(params), params);
  }

  getById(params: LimitRequestParams): Observable<Limit> {
    return this.http.get<Limit>(this.getUrl(params));
  }

  update(params: LimitRequestParams, body: UpdateLimit): Observable<Limit> {
    return this.http.put<Limit>(this.getUrl(params), body);
  }

  delete(params: LimitRequestParams) {
    return this.http.delete(this.getUrl(params));
  }

  applyLimits(params: ApplyLimitRequestParams, body: ApplyLimits) {
    return this.http.post<CreateLimit[]>(this.getUrl(params), body);
  }

  private getUrl(params: LimitRequestParams) {
    const { strategies, strategy_limits, categories } = environment.urls;
    const { sId, cId, lId } = params;

    return `${categories}/${String(cId)}/${strategies}/${String(sId)}/${strategy_limits}${lId ? '/' + lId : ''}`;
  }
}
