import { Injectable, inject } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Operator } from '@enums/operator';
import { createBooleanFieldOptions } from '@modules/directives/customForm/boolean-field-options';
import { TranslateService } from '@ngx-translate/core';
import { MENU_OPTIONS } from '../../utils/constants';
import { StrategyLimitStateService } from '../strategy-limit-state/strategy-limit-state.service';

@Injectable()
export class StrategyLimitFormService {
  private translate = inject(TranslateService);

  private controlListWithValidator: readonly NgModel[] = [];

  limitState = inject(StrategyLimitStateService);

  get limit() {
    return this.limitState.getCurrentLimit();
  }

  propertyOperatorOptionList = this.getPropertyOperatorOptionList();

  operatorMenuList = this.createOperatorMenuOptionList();

  booleanOptionList = createBooleanFieldOptions().map((el, id) => ({ ...el, id }));

  private updateControlsWithValidators = () =>
    this.controlListWithValidator
      .filter((c) => !!c.control.validator)
      .map((c) => {
        c.control.markAsTouched();
        c.control.updateValueAndValidity();
        return c;
      });

  private validate = () => this.updateControlsWithValidators();

  valid = () => {
    const controls = this.validate();
    return controls.length === controls.reduce((a, c) => a + +!!c.valid, 0);
  };

  setControls(controls: readonly NgModel[]) {
    this.controlListWithValidator = controls;
  }

  private createOperatorMenuOptionList() {
    return MENU_OPTIONS.map((o) => ({ ...o, name: this.translate.instant(o.name) }));
  }

  private getPropertyOperatorOptionList() {
    return [
      {
        name: this.translate.instant('strategy.limits.form.3.equal'),
        value: Operator['='],
      },
      {
        name: this.translate.instant('strategy.limits.form.3.not_equal'),
        value: Operator['!='],
      },
      {
        name: this.translate.instant('strategy.limits.form.3.any'),
        value: Operator.Any,
      },
    ];
  }
}
